/* eslint-disable react/display-name */
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import { Column, Grid } from 'src/components/molecules/Grid';
import { InlineList } from 'src/components/molecules/InlineList';
import { Section } from 'src/components/molecules/Section';
import { Link } from 'src/containers/Link';
import { Logo as LogoNew } from 'src/containers/Logo.new';

export default function Drawer({ customData }) {
  const logoData = useStaticQuery(graphql`
    query {
      andreessen_horowitz: file(relativePath: { eq: "andreessen_horowitz.svg" }) {
        ...LogoImageDataNoSharp
      }
      accel: file(relativePath: { eq: "accel_logo.png" }) {
        ...LogoImageData
      }
      bsv: file(relativePath: { eq: "bsv_logo.png" }) {
        ...LogoImageData
      }
      paypal: file(relativePath: { eq: "paypal_logo.png" }) {
        ...LogoImageData
      }
      stepstone: file(relativePath: { eq: "stepstone_logo.png" }) {
        ...LogoImageData
      }
    }
  `);

  customData = JSON.parse(customData);
  return (
    <Section {...customData?.flags}>
      <h2>Our Investors</h2>

      <InlineList centered>
        <li>
          <Link to="https://a16z.com/" target="_blank">
            <LogoNew data={logoData.andreessen_horowitz} alt="Andreessen Horowitz" />
          </Link>
        </li>
        <li>
          <Link to="https://basisset.ventures/" target="_blank">
            <LogoNew data={logoData.bsv} alt="Basisset Ventures" />
          </Link>
        </li>
        <li>
          <Link to="https://www.accel.com/" target="_blank">
            <LogoNew data={logoData.accel} alt="Accel" />
          </Link>
        </li>
        <li>
          <Link to="https://paypal.vc/home/default.aspx" target="_blank">
            <LogoNew data={logoData.paypal} alt="Paypal Ventures" />
          </Link>
        </li>
        <li>
          <Link to="https://www.stepstonegroup.com" target="_blank">
            <LogoNew data={logoData.stepstone} alt="Stepstone" />
          </Link>
        </li>
      </InlineList>

      <br />
      <br />

      <Grid columns="1fr 1fr 1fr">
        <Column>
          <h3>Matthaus Krzykowski</h3>
          <p>Salisbury Ventures</p>
          <h3>Robin Vasan</h3>
          <p>Mango Capital</p>
          <h3>Florian Leibert</h3>
          <p>Founder @ Mesosphere</p>
          <h3>Alex Rinke</h3>
          <p>Co-founder &amp; Co-CEO @ Celonis</p>
        </Column>

        <Column>
          <h3>Ross Mason</h3>
          <p>Founder @ MuleSoft</p>
          <h3>Mitchell Hashimoto</h3>
          <p>Founder &amp; CTO @ Hashicorp</p>
          <h3>Greg Brockman</h3>
          <p>Co-founder &amp; CTO @ OpenAI</p>
        </Column>

        <Column>
          <h3>John Newton</h3>
          <p>Founder &amp; CTO @ Alfresco</p>
          <h3>Paul Dix</h3>
          <p>Co-founder &amp; CTO @ InfluxData</p>
          <h3>Daniel Dines</h3>
          <p>Founder &amp; CEO @ UI Path</p>
        </Column>
      </Grid>
    </Section>
  );
}
